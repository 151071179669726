import { useEffect, useState } from 'react';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import { Box, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import InformationAlert from '@common/components/alerts/InformationAlert';
import Breadcrumb from '@common/components/BreadCrumb';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog';
import DialogButton from '@common/components/dialogs/DialogButton';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import Page from '@common/components/Page';
import { urls } from '@common/helpers/urls';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { getElapsedTime } from '@common/utils/date';
import {
    deleteReportTemplate,
    getAllReportTemplates,
    getReportTemplateById,
    setActiveReportTemplate,
    updateReportTemplateName
} from '@setup/api/reportTemplates/reportTemplates';
import TemplateCardLoading from './TemplateCardLoading';
import TemplateItem from './TemplateItem';
import TemplatesLibraryDialog from './TemplatesLibraryDialog';
import { orderTemplatesByEditedTime } from './utils';

export default function TemplatesRepositoryPage() {
    const { getPlan } = useUser();

    const [newTemplateName, setNewTemplateName] = useState('');
    const [templates, setTemplates] = useState();
    const [workspaceTemplates, setWorkspaceTemplates] = useState();
    const [actionTemplateId, setActionTemplateId] = useState(-1);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [showNameDialog, setShowNameDialog] = useState(false);
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [openTemplatesLibraryDialog, setOpenTemplatesLibraryDialog] = useState(false);

    useEffect(() => {
        (async () => {
            const { data } = await getAllReportTemplates();
            setTemplates(orderTemplatesByEditedTime(data.userTemplates));
            setWorkspaceTemplates(data.workspaceTemplates);
        })();
    }, []);

    useEffect(() => {
        if (templates) {
            const currentTemplate = templates.find((t) => t.id === actionTemplateId);
            if (currentTemplate) setNewTemplateName(currentTemplate.name);
        }
    }, [actionTemplateId]);

    const handleDelete = async (reportTemplateId) => {
        await deleteReportTemplate({ reportTemplateId });
        setTemplates(templates.filter((t) => t.id !== reportTemplateId));
        setWorkspaceTemplates(workspaceTemplates.filter((t) => t.id !== reportTemplateId));
    };

    const handleSetActive = async (reportTemplateId) => {
        if (getPlan().name !== subscriptionPlans.unlimited.name) {
            setOpenPremiumDialog(true);
            return;
        }
        await setActiveReportTemplate({ reportTemplateId });
        setTemplates(
            orderTemplatesByEditedTime(
                templates.map((t) => {
                    if (t.id === reportTemplateId) {
                        t.isActive = true;
                    } else {
                        t.isActive = false;
                    }

                    return t;
                })
            )
        );
    };

    const handleChangeName = async () => {
        // TODO: We shouldnt update sections
        const { data: template } = await getReportTemplateById({
            reportTemplateId: actionTemplateId
        });
        const data = {
            name: newTemplateName || 'Nueva plantilla',
            sections: template.sections.map((s, i) => {
                return {
                    position: i,
                    type: s.type
                };
            })
        };
        await updateReportTemplateName({ name: data.name, reportTemplateId: actionTemplateId });
        setTemplates(
            orderTemplatesByEditedTime(
                templates.map((t) => {
                    if (t.id === actionTemplateId) {
                        t.name = newTemplateName;
                    }
                    return t;
                })
            )
        );
        return true;
    };

    return (
        <Page title='Plantillas'>
            <Box sx={{ display: 'flex', paddingTop: 4 }}>
                <Box
                    component='main'
                    sx={{
                        backgroundColor: 'white',
                        flexGrow: 1,
                        height: 'auto',
                        overflow: 'auto'
                    }}
                >
                    <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                        <Grid container direction='column' spacing={3}>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Breadcrumb currentPageTitle={'Plantillas'} />
                            </Grid>
                            {getPlan().name !== subscriptionPlans.unlimited.name && (
                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <InformationAlert
                                        message=' 🔐 <b>Desbloquea</b> las plantillas de resúmenes actualizando al <b>plan Unlimited</b> para extraer la información clave de diferentes tipos reuniones 🎯'
                                        onClick={() => setOpenPremiumDialog(true)}
                                        buttonText='Mejorar plan'
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Stack spacing={2}>
                                    <Typography component='span' variant='h4'>
                                        {'Tus plantillas'}
                                    </Typography>
                                    <Typography
                                        component={'span'}
                                        variant='body1'
                                        color={palette.primary[300]}
                                    >
                                        Utiliza plantillas para extraer datos o información de
                                        reuniones dónde ya sabes los puntos que quieres extraer.
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid sx={{ mt: 2 }} container direction='row' spacing={2}>
                            {/* Create new template button */}
                            <Grid item>
                                <TemplateItem
                                    isAddHandler={true}
                                    onHandlerClick={() => {
                                        if (getPlan().name === subscriptionPlans.unlimited.name) {
                                            setOpenTemplatesLibraryDialog(true);
                                        } else {
                                            setOpenPremiumDialog(true);
                                        }
                                    }}
                                />
                            </Grid>
                            {templates ? (
                                <>
                                    {templates.map((tt, i) => {
                                        return (
                                            <Grid key={i} item>
                                                <TemplateItem
                                                    onDelete={async (id) => {
                                                        setShowDeleteConfirmationDialog(true);
                                                        setActionTemplateId(id);
                                                    }}
                                                    onSetActive={async (id) =>
                                                        await handleSetActive(id)
                                                    }
                                                    onNameChange={async (id) => {
                                                        setShowNameDialog(true);
                                                        setActionTemplateId(id);
                                                    }}
                                                    name={tt.name}
                                                    isActive={tt.isActive}
                                                    isShared={!!tt.workspaceId}
                                                    id={tt.id}
                                                    editionDate={
                                                        tt.updatedAt
                                                            ? getElapsedTime({
                                                                  fromDate: new Date(tt.updatedAt),
                                                                  toDate: Date.now()
                                                              })
                                                            : null
                                                    }
                                                    language={tt.language}
                                                />
                                            </Grid>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    <Grid key={1} item>
                                        <TemplateCardLoading />
                                    </Grid>
                                    <Grid key={2} item>
                                        <TemplateCardLoading />
                                    </Grid>
                                    <Grid key={3} item>
                                        <TemplateCardLoading />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid container direction={'row'} sx={{ mt: 5, mb: 5 }}>
                            <Grid item sx={{ mr: 2 }}>
                                <Button
                                    variant='text'
                                    startIcon={<OndemandVideoRoundedIcon fontSize='small' />}
                                    onClick={() => window.open(urls.tutorials.templates)}
                                >
                                    Cómo personalizar mi plantilla
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='text'
                                    startIcon={<CampaignOutlinedIcon fontSize='small' />}
                                    onClick={() => window.open(urls.forms.templates)}
                                >
                                    Haznos saber qué tipos de reuniones tienes
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            {openTemplatesLibraryDialog && (
                <TemplatesLibraryDialog
                    openDialog={openTemplatesLibraryDialog}
                    setOpenDialog={setOpenTemplatesLibraryDialog}
                    workspaceTemplates={workspaceTemplates}
                />
            )}
            {showDeleteConfirmationDialog && (
                <ConfirmationDialog
                    open={showDeleteConfirmationDialog}
                    onConfirm={async () => {
                        await handleDelete(actionTemplateId);
                        setShowDeleteConfirmationDialog(false);
                    }}
                    onClose={() => setShowDeleteConfirmationDialog(false)}
                    title='¿Estás seguro?'
                    message='Eliminar esta plantilla hará que no puedas usarla para documentar tus reuniones'
                />
            )}
            {showNameDialog && (
                <DialogButton
                    dialogTitle='Nombre de tu plantilla'
                    dialogText='Utiliza un nombre fácilmente reconocible para localizar tu plantilla y su propósito.'
                    withButton={false}
                    successButtonLoadingText='Guardando'
                    successButtonText='Guardar'
                    onSuccess={async () => await handleChangeName()}
                    openDialog={showNameDialog}
                    onClose={() => setShowNameDialog(false)}
                >
                    <TextField
                        onInput={async (e) => {
                            setNewTemplateName(e.target.value);
                        }}
                        value={newTemplateName}
                        style={{ marginTop: '15px' }}
                        fullWidth
                        placeholder='Nombre de tu plantilla'
                        variant='standard'
                        label='Nombre'
                    />
                </DialogButton>
            )}
            <PricingDialog
                openDialog={openPremiumDialog}
                setOpenDialog={setOpenPremiumDialog}
                title='Actualiza a Unlimited para desbloquear tus plantillas'
                defaultPlanId={1}
            />
        </Page>
    );
}
