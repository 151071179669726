import React, { forwardRef, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router';
import ToggleButton from '@common/components/dialogs/plansDialog/ToggleButton';
import ItemsSelector from '@common/components/form/ItemsSelector';
import SummaryMockup from '@common/components/SummaryMockup';
import { navigation } from '@common/helpers/navigation';
import { DEFAULT_TEMPLATE_SECTIONS } from '@common/helpers/templates/defaultTemplateSections';
import predefinedTemplates from '@common/helpers/templates/predefinedTemplates';
import useWorkspace from '@common/hooks/useWorkspace';
import {
    createReportTemplate,
    getReportTemplateById
} from '@setup/api/reportTemplates/reportTemplates';
import { getSectionById } from '@setup/api/section/sections';

TemplatesLibraryDialog.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    setOpenDialog: PropTypes.func.isRequired,
    workspaceTemplates: PropTypes.any
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export default function TemplatesLibraryDialog({ openDialog, setOpenDialog, workspaceTemplates }) {
    const navigate = useNavigate();
    const defaultTemplate = 'other';
    const { workspace } = useWorkspace();

    const [currentTemplateId, setCurrentTemplateId] = useState(null);
    const [template, setTemplate] = useState([defaultTemplate]);
    const [lastTemplate, setLastTemplate] = useState(
        predefinedTemplates.find((template) => template.id === defaultTemplate)
    );
    const [toggleOptions, setToggleOptions] = useState([{ key: 'community', label: 'Comunidad' }]);
    const [currentTab, setCurrentTab] = useState(toggleOptions[0].key);
    const [templates, setTemplates] = useState();
    const [loadingTemplates, setLoadingTemplates] = useState(false);

    const handleChangeTab = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleCreateTemplate = async () => {
        const selectedTemplate =
            currentTab === 'team'
                ? templates.find((t) => t.id === template[0])
                : predefinedTemplates.find((t) => t.id === template[0]);
        const data = {
            name: selectedTemplate.name,
            sections: selectedTemplate.sections.map((s, i) => {
                return {
                    position: i,
                    type: s.type,
                    sectionTitle: s.sectionTitle,
                    subject: s.subject,
                    sectionId: s?.sectionId
                };
            })
        };

        const res = await createReportTemplate(data);
        if (res.id) navigate(`/templates/edit/${res.id}`);
    };

    useEffect(() => {
        if (currentTemplateId) {
            if (typeof currentTemplateId === 'number') {
                setLastTemplate(templates.find((template) => template.id === currentTemplateId));
            } else {
                setLastTemplate(
                    predefinedTemplates.find((template) => template.id === currentTemplateId)
                );
            }
        }
    }, [currentTemplateId]);

    useEffect(() => {
        if (workspace?.name && workspaceTemplates.length > 0) {
            setLoadingTemplates(true);
            setToggleOptions((options) => {
                const exists = options.some((option) => option.key === 'team');
                if (!exists) {
                    return [{ key: 'team', label: workspace.name }, ...options];
                }
                return options;
            });
            setCurrentTab('team');
            if (!templates) {
                (async () => {
                    const sharedTemplates = [];
                    for (let i = 0; i < workspaceTemplates.length; i++) {
                        const sections = [];

                        const { data: templateData } = await getReportTemplateById({
                            reportTemplateId: workspaceTemplates[i].id
                        });

                        for (let n = 0; n < templateData.sections.length; n++) {
                            if (templateData.sections[n].type === 'personalized') {
                                const section = await getSectionById(
                                    templateData.sections[n].sectionId
                                );
                                sections.push({ ...templateData.sections[n], title: section.name });
                            } else {
                                sections.push({
                                    ...templateData.sections[n],
                                    title: Object.values(DEFAULT_TEMPLATE_SECTIONS).find(
                                        (section) => section.type === templateData.sections[n].type
                                    ).title
                                });
                            }
                        }
                        sharedTemplates.push({ ...workspaceTemplates[i], sections });
                        setTemplates(sharedTemplates);
                    }
                    setLoadingTemplates(false);
                })();
            }
        }
    }, [workspace]);

    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            TransitionComponent={Transition}
            maxWidth={'md'}
            fullWidth={true}
            sx={{
                '& .MuiDialog-paper': {
                    height: '90vh', // Set max height to 90% of the viewport height
                    overflow: 'auto' // Enable scrolling if content exceeds this height
                }
            }}
        >
            <DialogTitle>
                Librería de plantillas de informes
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction='row'
                    alignItems='flex-start'
                    justifyContent='space-evenly'
                    mt={4}
                >
                    <Grid
                        item
                        xs={5}
                        sx={{
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        {workspaceTemplates?.length > 0 && (
                            <Grid item sx={{ mb: 2 }}>
                                <ToggleButton
                                    options={toggleOptions}
                                    defaultOptionId={
                                        currentTab
                                            ? toggleOptions.findIndex(
                                                  (option) => option.key === currentTab
                                              )
                                            : 0
                                    }
                                    fullWidth={true}
                                    handleChange={(tabValue) => {
                                        handleChangeTab(toggleOptions[tabValue].key);
                                    }}
                                />
                            </Grid>
                        )}

                        {currentTab === 'team' && templates ? (
                            <ItemsSelector
                                multipleSelection={false}
                                selectedItemsIds={template}
                                setSelectedItemsIds={setTemplate}
                                setCurrentItem={setCurrentTemplateId}
                                items={templates}
                                avatarName={true}
                                spacing={0.5}
                                direction='column'
                                loading={loadingTemplates}
                            />
                        ) : (
                            <ItemsSelector
                                multipleSelection={false}
                                selectedItemsIds={template}
                                setSelectedItemsIds={setTemplate}
                                setCurrentItem={setCurrentTemplateId}
                                items={predefinedTemplates.filter(
                                    (template) => template.id !== 'other'
                                )}
                                spacing={0.5}
                                direction='column'
                                loading={loadingTemplates}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: -3
                        }}
                    >
                        <SummaryMockup title={lastTemplate.name} sections={lastTemplate.sections} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={() => navigate(navigation.app.routes.newTemplate)}>
                    Continuar sin plantilla
                </Button>
                <Button onClick={handleCreateTemplate} variant='contained' disableElevation={true}>
                    Utilizar plantilla
                </Button>
            </DialogActions>
        </Dialog>
    );
}
