import React from 'react';
import AvatarMUI from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { PropTypes } from 'prop-types';
import palette from '@common/theme/palette/palette';
import { getColorFromLetter } from '@common/utils/colors';

export default function Avatar({ size, avatarName, tooltip, avatarImage, customTooltipText, sx }) {
    const sizeList = [
        {
            size: 'small',
            shapeSize: '24px',
            typoSize: 'caption'
        },
        {
            size: 'medium',
            shapeSize: '42px',
            typoSize: 'subtitle1'
        },
        {
            size: 'large',
            shapeSize: '64px',
            typoSize: 'h4'
        },
        {
            size: 'xl',
            shapeSize: '92px',
            typoSize: 'h3'
        },
        {
            size: 'xxl',
            shapeSize: '140px',
            typoSize: 'h2'
        }
    ];

    function stringAvatar(name) {
        if (name) {
            const arrName = name.split(' ');
            const firstName = arrName[0];
            const secondName = arrName[1];

            if (firstName) {
                const firstLetter = firstName.charAt(0).toUpperCase();
                let stringAvatar = firstLetter;
                if (secondName) {
                    const secondLetter = secondName.charAt(0).toUpperCase();
                    stringAvatar += secondLetter;
                }
                return stringAvatar;
            }
        }
        return;
    }

    for (var i = 0; i < sizeList.length; i++) {
        if (sizeList[i].size === size) {
            return (
                <>
                    {tooltip ? (
                        <Tooltip title={customTooltipText ? customTooltipText : avatarName}>
                            <AvatarMUI
                                alt={avatarName}
                                src={avatarImage}
                                sx={{
                                    ...sx,
                                    bgcolor: getColorFromLetter(avatarName).full[100],
                                    color: getColorFromLetter(avatarName).full.contrastText,
                                    width: size ? sizeList[i].shapeSize : '24px',
                                    height: size ? sizeList[i].shapeSize : '24px',
                                    border: `1px solid ${
                                        avatarImage
                                            ? palette.common.white
                                            : getColorFromLetter(avatarName).full.main
                                    }`
                                }}
                                variant='rounded'
                            >
                                <Typography component='span' variant={sizeList[i].typoSize}>
                                    {stringAvatar(avatarName)}
                                </Typography>
                            </AvatarMUI>
                        </Tooltip>
                    ) : (
                        <AvatarMUI
                            alt={avatarName}
                            src={avatarImage}
                            sx={{
                                ...sx,
                                bgcolor: getColorFromLetter(avatarName).full[100],
                                color: getColorFromLetter(avatarName).full.contrastText,
                                width: size ? sizeList[i].shapeSize : '24px',
                                height: size ? sizeList[i].shapeSize : '24px',
                                border: `1px solid ${
                                    avatarImage
                                        ? palette.chip_primary[300]
                                        : getColorFromLetter(avatarName).full.main
                                }`
                            }}
                            variant='rounded'
                        >
                            <Typography component='span' variant={sizeList[i].typoSize}>
                                {stringAvatar(avatarName)}
                            </Typography>
                        </AvatarMUI>
                    )}
                </>
            );
        }
    }
}

Avatar.propTypes = {
    size: PropTypes.string,
    avatarName: PropTypes.string.isRequired,
    tooltip: PropTypes.bool,
    avatarImage: PropTypes.string,
    customTooltipText: PropTypes.string,
    sx: PropTypes.any
};
