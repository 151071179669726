import React from 'react';
import { Grid, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import palette from '@common/theme/palette/palette';
import shadows from '@common/theme/palette/shadows';
import Tooltip from './Tooltip';

SummaryMockup.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.any),
    title: PropTypes.string,
    darkMode: PropTypes.bool
};

export default function SummaryMockup({ title, sections, darkMode }) {
    const getRandomValue = (min = 10, max = 90) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };
    const randomWidths = sections.map(() => getRandomValue());

    return (
        <Tooltip text='Podrás personalizar esta plantilla después' placement='bottom'>
            <Grid
                container
                direction={'column'}
                sx={{
                    border: '1px solid',
                    borderColor: darkMode ? palette.primary[600] : palette.primary[200],
                    borderRadius: '8px',
                    backgroundColor: darkMode ? palette.primary[800] : palette.common.white,
                    boxShadow: shadows.floating
                }}
                p={2}
                mt={2}
                width={'440px'}
            >
                <Grid display='flex' flexDirection={'row'} justifyContent='start' item>
                    <Typography
                        variant='body2'
                        color={darkMode ? palette.primary[300] : palette.primary[500]}
                    >
                        Plantilla de tu resumen
                    </Typography>
                </Grid>
                <Grid
                    item
                    display='flex'
                    flexDirection={'row'}
                    justifyContent='start'
                    sx={{ mb: 1 }}
                >
                    <Typography
                        variant='h5'
                        color={darkMode ? palette.primary[50] : palette.primary.main}
                    >
                        {title}
                    </Typography>
                </Grid>
                {sections.map((section, i) => (
                    <Grid item key={section.id} flexGrow={1} mb={0.5}>
                        <Grid container direction='column' display='flex'>
                            <Grid display='flex' flexDirection={'row'} justifyContent='start' item>
                                <Typography
                                    variant='body1'
                                    color={darkMode ? palette.primary[50] : palette.primary.main}
                                >
                                    {section.type === 'specific_competence' && section.sectionTitle
                                        ? section.sectionTitle
                                        : section.title}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component='div' key='s01-02' variant='body1'>
                                    <Skeleton
                                        animation='wave'
                                        width={`${randomWidths[i]}%`}
                                        sx={{
                                            bgcolor: darkMode
                                                ? palette.primary[700]
                                                : palette.primary[50]
                                        }}
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Tooltip>
    );
}
