import { authApiCall, endpoints } from '..';

export async function getReportTemplateById({ reportTemplateId }) {
    return authApiCall({
        method: 'GET',
        endpoint: endpoints.template,
        path: `/${reportTemplateId}`
    });
}

export async function getAllReportTemplates() {
    return authApiCall({
        method: 'GET',
        endpoint: endpoints.template,
        path: '/all'
    });
}

export async function getActiveTemplate() {
    return authApiCall({
        method: 'GET',
        endpoint: endpoints.template,
        path: '/active'
    });
}

export async function createReportTemplate({ name, sections }) {
    const { data } = await authApiCall({
        method: 'POST',
        data: {
            name,
            sections
        },
        endpoint: endpoints.template,
        path: '/create'
    });
    return data;
}

export async function updateReportTemplateSections({ name, sections, reportTemplateId }) {
    return await authApiCall({
        method: 'PUT',
        data: {
            reportTemplateId,
            name,
            sections
        },
        endpoint: endpoints.template,
        path: '/update/sections'
    });
}

export async function updateReportTemplateName({ name, reportTemplateId }) {
    return await authApiCall({
        method: 'PUT',
        data: {
            reportTemplateId,
            name
        },
        endpoint: endpoints.template,
        path: '/update/name'
    });
}

export async function updateReportTemplateLanguage({ language, reportTemplateId }) {
    return await authApiCall({
        method: 'PUT',
        data: {
            reportTemplateId,
            language
        },
        endpoint: endpoints.template,
        path: '/update/language'
    });
}

export async function setActiveReportTemplate({ reportTemplateId }) {
    return authApiCall({
        method: 'PUT',
        data: {
            reportTemplateId
        },
        endpoint: endpoints.template,
        path: '/activate'
    });
}

export async function deleteReportTemplate({ reportTemplateId }) {
    return authApiCall({
        method: 'DELETE',
        data: {
            reportTemplateId
        },
        endpoint: endpoints.template,
        path: '/delete'
    });
}

export async function shareTemplate({ templateId }) {
    const { status } = await authApiCall({
        method: 'PUT',
        endpoint: endpoints.template,
        path: `/share/${templateId}`
    });
    return status;
}

export async function duplicateTemplate({ templateId }) {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.template,
        path: `/duplicate/${templateId}`
    });
    return data;
}
