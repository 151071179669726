import { useEffect, useState } from 'react';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import ShortTextRoundedIcon from '@mui/icons-material/ShortTextRounded';
import SubjectIcon from '@mui/icons-material/Subject';
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import InformationAlert from '@common/components/alerts/InformationAlert';
import IconButtonWithTooltip from '@common/components/buttons/IconButtonWithTooltip';
import SwitchButton from '@common/components/buttons/SwitchButton';
import ConfirmationDialog from '@common/components/dialogs/ConfirmationDialog';
import useNotification from '@common/hooks/useNotification';
import useWorkspace from '@common/hooks/useWorkspace';
import palette from '@common/theme/palette/palette';
import { getIconFromFormatType } from '@pages/template/utils';
import { createSection, deleteSection, shareSection } from '@setup/api/section/sections';

TemplateMenuSectionConfig.propTypes = {
    sections: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleNavigateToPersonalizedSections: PropTypes.func.isRequired,
    handleAddNewSection: PropTypes.func.isRequired,
    currentSection: PropTypes.any,
    handleRemoveSection: PropTypes.func.isRequired,
    handleUpdateSection: PropTypes.func.isRequired,
    personalizedSections: PropTypes.any
};

export default function TemplateMenuSectionConfig({
    handleNavigateToPersonalizedSections,
    handleAddNewSection,
    currentSection,
    handleRemoveSection,
    handleUpdateSection,
    personalizedSections
}) {
    const notification = useNotification();
    const { workspaceExists } = useWorkspace();

    const [selectedFormat, setSelectedFormat] = useState(
        currentSection ? currentSection.formatType : 'paragraph'
    );
    const [name, setName] = useState('');
    const [goal, setGoal] = useState('');
    const [guidelines, setGuidelines] = useState('');
    const [alertName, setAlertName] = useState('');
    const [alertGoal, setAlertGoal] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [changeToSave, setChangeToSave] = useState(false);
    const [isSharedSection, setIsSharedSection] = useState(false);

    useEffect(() => {
        if (currentSection) {
            if (
                currentSection.name !== name ||
                currentSection.goal !== goal ||
                currentSection.guidelines !== guidelines ||
                currentSection.formatType !== selectedFormat
            ) {
                setChangeToSave(true);
            }
        }
    }, [name, goal, guidelines, selectedFormat]);

    useEffect(() => {
        if (currentSection) {
            handleInitializeSection();
            if (
                personalizedSections.length <= 0 ||
                !personalizedSections.some((item) => item.id === currentSection.id)
            ) {
                setIsSharedSection(true);
            }
        }
    }, [currentSection]);

    const handleInitializeSection = () => {
        setName(currentSection.name);
        setGoal(currentSection.goal);
        setGuidelines(currentSection.guidelines);
        setSelectedFormat(currentSection.formatType);
        setIsActive(currentSection.workspaceId ? true : false);
    };

    const handleCreateSection = async () => {
        try {
            if (!name || !goal) {
                if (!name) setAlertName('Introduce un nombre para tu sección');
                if (!goal) setAlertGoal('Especifica que información quieres extraer');
                return;
            }
            const createdSection = await createSection({
                name,
                goal,
                guidelines,
                formatType: selectedFormat
            });
            if (createdSection.id) {
                handleAddNewSection({
                    description: createdSection.goal,
                    props: {
                        title: createdSection.name,
                        icon: getIconFromFormatType(createdSection.formatType), // depends in the format type
                        type: 'personalized',
                        new: true,
                        id: createdSection.id
                    },
                    ...createdSection
                });
                handleNavigateToPersonalizedSections();
            } else {
                notification('create-section-failure');
            }
        } catch (error) {
            notification('create-section-failure');
        }
    };

    const handleShareSection = async () => {
        try {
            const status = await shareSection({ sectionId: currentSection.id });
            if (status !== 200) {
                notification('update-section-failure');
                return;
            }
            setIsActive(!isActive);
        } catch (error) {
            notification('update-section-failure');
        }
    };

    const handleDeleteSection = async () => {
        try {
            const status = await deleteSection(currentSection.id);
            if (status === 200) {
                handleRemoveSection(currentSection);
                handleNavigateToPersonalizedSections();
                return;
            }
            notification('delete-section-failure');
        } catch (error) {
            notification('delete-section-failure');
        }
    };

    const handleSaveSection = async () => {
        try {
            const status = await handleUpdateSection({
                id: currentSection.id,
                name,
                goal,
                guidelines,
                formatType: selectedFormat
            });
            if (status === 200) {
                setChangeToSave(false);
                return;
            }
            notification('update-section-failure');
        } catch (error) {
            notification('update-section-failure');
        }
    };

    return (
        <Stack spacing={3}>
            {isSharedSection && (
                <InformationAlert message='No tienes permiso para editar esta sección.' />
            )}
            <TextField
                error={alertName}
                helperText={alertName}
                margin='normal'
                size='small'
                required
                fullWidth
                id='name'
                label='Nombre'
                name='name'
                autoComplete='name'
                autoFocus
                variant='standard'
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={isSharedSection}
            />
            <TextField
                error={alertGoal}
                helperText={alertGoal}
                margin='normal'
                size='small'
                required
                fullWidth
                id='goal'
                label='¿Qué información quieres extraer?'
                name='goal'
                multiline
                autoComplete='goal'
                variant='standard'
                value={goal}
                onChange={(e) => setGoal(e.target.value)}
                disabled={isSharedSection}
            />
            <TextField
                margin='normal'
                size='small'
                fullWidth
                id='guidelines'
                label='Instrucciones'
                name='guidelines'
                multiline
                autoComplete='guidelines'
                variant='standard'
                value={guidelines}
                onChange={(e) => setGuidelines(e.target.value)}
                disabled={isSharedSection}
            />
            <Typography variant='body1' color={palette.primary.main}>
                Formato
                <Stack direction='row' spacing={1} marginTop={1}>
                    <IconButtonWithTooltip
                        id='multi_paragraph'
                        icon={<SubjectIcon fontSize='small' />}
                        onClick={() => setSelectedFormat('multi_paragraph')}
                        sx={{
                            ...(selectedFormat === 'multi_paragraph' && {
                                border: `1px solid ${palette.primary.main}`,
                                backgroundColor: palette.primary[50]
                            })
                        }}
                        tooltipText='Varios párrafos'
                        disabled={isSharedSection}
                    />
                    <IconButtonWithTooltip
                        id='paragraph'
                        icon={<NotesRoundedIcon fontSize='small' />}
                        onClick={() => setSelectedFormat('paragraph')}
                        sx={{
                            ...(selectedFormat === 'paragraph' && {
                                border: `1px solid ${palette.primary.main}`,
                                backgroundColor: palette.primary[50]
                            })
                        }}
                        tooltipText='Párrafo'
                        disabled={isSharedSection}
                    />
                    <IconButtonWithTooltip
                        id='phrase'
                        icon={<ShortTextRoundedIcon fontSize='small' />}
                        onClick={() => setSelectedFormat('phrase')}
                        sx={{
                            ...(selectedFormat === 'phrase' && {
                                border: `1px solid ${palette.primary.main}`,
                                backgroundColor: palette.primary[50]
                            })
                        }}
                        tooltipText='Frase'
                        disabled={isSharedSection}
                    />
                    <IconButtonWithTooltip
                        id='list'
                        icon={<FormatListBulletedRoundedIcon fontSize='small' />}
                        onClick={() => setSelectedFormat('list')}
                        sx={{
                            ...(selectedFormat === 'list' && {
                                border: `1px solid ${palette.primary.main}`,
                                backgroundColor: palette.primary[50]
                            })
                        }}
                        tooltipText='Lista'
                        disabled={isSharedSection}
                    />
                    <IconButtonWithTooltip
                        id='number'
                        icon={<NumbersRoundedIcon fontSize='small' />}
                        onClick={() => setSelectedFormat('number')}
                        sx={{
                            ...(selectedFormat === 'number' && {
                                border: `1px solid ${palette.primary.main}`,
                                backgroundColor: palette.primary[50]
                            })
                        }}
                        tooltipText='Número'
                        disabled={isSharedSection}
                    />
                </Stack>
            </Typography>
            {currentSection ? (
                <>
                    {changeToSave && !isSharedSection && (
                        <Button
                            type='submit'
                            variant='contained'
                            onClick={handleSaveSection}
                            disableElevation={true}
                            fullWidth={true}
                            color='primary'
                            size='small'
                        >
                            {'Guardar'}
                        </Button>
                    )}
                </>
            ) : (
                <Button
                    type='submit'
                    variant='contained'
                    onClick={handleCreateSection}
                    disableElevation={true}
                    fullWidth={true}
                    color='primary'
                    size='small'
                >
                    {'Crear sección'}
                </Button>
            )}
            {currentSection && !isSharedSection && (
                <>
                    {workspaceExists && (
                        <FormControl component='fieldset' variant='standard'>
                            <FormLabel component='legend' color='primary'>
                                Compartir
                            </FormLabel>
                            <FormControlLabel
                                control={
                                    <SwitchButton
                                        checked={isActive}
                                        onChange={handleShareSection}
                                    />
                                }
                                label={
                                    isActive
                                        ? 'Tu equipo tiene acceso a esta sección'
                                        : 'Tu equipo tendrá acceso a esta sección'
                                }
                                labelPlacement='start'
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    mt: -1,
                                    ml: 0,
                                    '& .MuiFormControlLabel-label': {
                                        color: palette.primary[400],
                                        typography: 'body2'
                                    }
                                }}
                            />
                        </FormControl>
                    )}
                    <FormControl component='fieldset' variant='standard'>
                        <FormLabel component='legend' color='primary'>
                            Eliminar
                        </FormLabel>
                        <FormControlLabel
                            control={
                                <IconButtonWithTooltip
                                    icon={<DeleteOutlineRoundedIcon />}
                                    tooltipText='Eliminar'
                                    onClick={() => setOpenDeleteDialog(true)}
                                />
                            }
                            label='Nadie podrá volver a utilizar esta sección'
                            labelPlacement='start'
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                mt: -1,
                                ml: 0,
                                '& .MuiFormControlLabel-label': {
                                    color: palette.primary[400],
                                    typography: 'body2'
                                }
                            }}
                        />
                    </FormControl>
                </>
            )}
            <ConfirmationDialog
                open={openDeleteDialog}
                title='Eliminar sección'
                message='Estas a punto de eliminar esta sección, si la eliminas nadie podrá volver a utilizarla.'
                onConfirm={handleDeleteSection}
                onClose={() => setOpenDeleteDialog(false)}
            />
        </Stack>
    );
}
