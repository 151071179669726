import { authApiCall, endpoints } from '.';

export const chat = async ({ newMessage, thread, meetingId }) => {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.nlp,
        path: `/chat/${meetingId}`,
        data: {
            newMessage,
            thread
        }
    });

    return data;
};

export const completion = async ({ prompt, system, config, user }) => {
    const { data } = await authApiCall({
        method: 'POST',
        endpoint: endpoints.nlp,
        path: `/create/completion`,
        data: {
            prompt,
            system,
            config: {
                model: config.model || 'gpt-3.5-turbo',
                temperature: config.temperature || 0.5,
                max_tokens: 400,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
                user
            }
        }
    });

    return data;
};
