import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DragDropContext } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router';
import InformationAlert from '@common/components/alerts/InformationAlert';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import HideableLayout from '@common/components/layout/HideableLayout';
import Page from '@common/components/Page';
import { navigation } from '@common/helpers/navigation';
import { DEFAULT_TEMPLATE_SECTIONS } from '@common/helpers/templates/defaultTemplateSections';
import { subscriptionPlans } from '@common/helpers/user';
import useNotification from '@common/hooks/useNotification';
import useUser from '@common/hooks/useUser';
import {
    createReportTemplate,
    getReportTemplateById,
    setActiveReportTemplate,
    shareTemplate,
    updateReportTemplateLanguage,
    updateReportTemplateName,
    updateReportTemplateSections
} from '@setup/api/reportTemplates/reportTemplates';
import { getAllSections, updateSection } from '@setup/api/section/sections';
import TemplateDragDropSections from './components/TemplateDragDropSections.jsx';
import TemplateSideMenu from './components/TemplateSideMenu';
import { getIconFromFormatType } from './utils';

export default function TemplatePage() {
    const { getPlan } = useUser();
    const notification = useNotification();

    const [name, setName] = useState('');
    const [isActive, setIsActive] = useState('');
    const [isShared, setIsShared] = useState(false);
    const [selectedSections, setSelectedSections] = useState();
    const [newChange, setNewChange] = useState(false);
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [userSections, setUserSections] = useState([]);
    const [workspaceSections, setWorkspaceSections] = useState([]);
    const [reportLanguage, setReportLanguage] = useState('es-ES');
    const [sectionIdFromTemplate, setSectionIdFromTemplate] = useState(undefined);

    const { id: templateId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (templateId) {
            (async () => {
                const { data: template } = await getReportTemplateById({
                    reportTemplateId: templateId
                });
                setIsActive(template.isActive);
                setIsShared(!!template.workspaceId);
                setName(template.name);
                setReportLanguage(template.language);
                const personalizedSections = [...userSections, ...workspaceSections];
                const sections = template.sections
                    .map((s) => {
                        if (s.sectionId) {
                            return {
                                ...personalizedSections.find((ps) => ps.id === s.sectionId),
                                ...personalizedSections.find((ps) => ps.id === s.sectionId).props,
                                ...s
                            };
                        }
                        return {
                            ...DEFAULT_TEMPLATE_SECTIONS[s.type],
                            ...s
                        };
                    })
                    .sort(
                        (currentSection, nextSection) =>
                            currentSection.position - nextSection.position
                    );
                setSelectedSections(sections);
            })();
            return;
        } else {
            // Adding empty sections
            setSelectedSections([
                DEFAULT_TEMPLATE_SECTIONS.summary,
                DEFAULT_TEMPLATE_SECTIONS.index,
                DEFAULT_TEMPLATE_SECTIONS.chronological_summary
            ]);
        }
    }, [userSections, workspaceSections]);

    useEffect(() => {
        if (newChange) {
            (async () => {
                const { data: template } = await getReportTemplateById({
                    reportTemplateId: templateId
                });
                const personalizedSections = [...userSections, ...workspaceSections];
                const sections = template.sections
                    .map((s) => {
                        if (s.sectionId) {
                            return {
                                ...personalizedSections.find((ps) => ps.id === s.sectionId),
                                ...personalizedSections.find((ps) => ps.id === s.sectionId).props,
                                ...s
                            };
                        }
                        return {
                            ...DEFAULT_TEMPLATE_SECTIONS[s.type],
                            ...s
                        };
                    })
                    .sort(
                        (currentSection, nextSection) =>
                            currentSection.position - nextSection.position
                    );
                setSelectedSections(sections);
            })();
            setNewChange(false);
        }
    }, [newChange]);

    useEffect(() => {
        (async () => {
            const personalizedSections = await getAllSections();
            // We should save adapting the sections to the client side
            setUserSections(
                personalizedSections.userSections
                    .map((section) => {
                        return {
                            id: section.id,
                            description: section.goal,
                            props: {
                                title: section.name,
                                icon: getIconFromFormatType(section.formatType), // depends in the format type
                                type: 'personalized',
                                id: section.id
                            }
                        };
                    })
                    .sort((currentSection, nextSection) => nextSection.id - currentSection.id)
            );
            setWorkspaceSections(
                personalizedSections.workspaceSections.map((section) => {
                    return {
                        id: section.id,
                        description: section.goal,
                        props: {
                            title: section.name,
                            icon: getIconFromFormatType(section.formatType), // depends in the format type
                            type: 'personalized'
                        }
                    };
                })
            );
        })();
    }, []);

    const previousPage = {
        title: 'Plantillas',
        handleNavigation: () => {
            navigate(navigation.app.routes.templates);
        }
    };

    const reorderSections = async (sourceIndex, destinationIndex) => {
        const selectedSectionsClone = [...selectedSections];
        selectedSectionsClone.splice(sourceIndex, 1);
        selectedSectionsClone.splice(destinationIndex, 0, selectedSections[sourceIndex]);
        setSelectedSections(selectedSectionsClone);
        handleSave(templateId, selectedSectionsClone);
    };

    const insertSectionAtPosition = async (destinationIndex, section) => {
        const selectedSectionsClone = [...selectedSections];
        selectedSectionsClone.splice(destinationIndex, 0, section);
        // setSelectedSections(selectedSectionsClone); // Not use anymore
        await handleSave(templateId, selectedSectionsClone);
        setNewChange(true); // Triggers useEffect to update sections
    };

    const handleDeleteSection = async (deletionIndex) => {
        const selectedSectionsClone = [...selectedSections];
        selectedSectionsClone.splice(deletionIndex, 1);
        // setSelectedSections(selectedSectionsClone);
        await handleSave(templateId, selectedSectionsClone);
        setNewChange(true); // Triggers useEffect to update sections
    };

    /**
     * This function is used to manage de drag and drop functionality for existing and new sections.
     *
     */
    const handleDragEnd = (result) => {
        const { destination, source } = result;
        const { draggableId } = result;
        const sectionReference = draggableId.split('-')[0];

        if (destination) {
            const isNotDroppingOnSelected =
                destination.droppableId !== 'selected-template-sections';
            const isFromSelectedToSelected =
                source.droppableId === 'selected-template-sections' &&
                destination.droppableId === 'selected-template-sections';

            if (isNotDroppingOnSelected) {
                // Selected but not dropped on the template
                return;
            }

            if (isFromSelectedToSelected) {
                // User is reordering
                const sourceIndex = source.index;
                const destinationIndex = destination.index;
                reorderSections(sourceIndex, destinationIndex);
                return;
            }

            // User is trying to add a section
            const alreadyExists = selectedSections.some(
                (section) =>
                    section.type === sectionReference ||
                    (section.type === 'personalized' &&
                        section.sectionId === Number(sectionReference))
            );

            if (alreadyExists) {
                return; // Do not insert the section if it already exists
            }

            const sectionId = Number(sectionReference); // Personalized sections add its ID into the draggable id
            if (sectionId) {
                insertSectionAtPosition(destination.index, { type: 'personalized', sectionId });
            } else {
                insertSectionAtPosition(
                    destination.index,
                    DEFAULT_TEMPLATE_SECTIONS[sectionReference]
                );
            }
            return;
        }

        if (source.droppableId.indexOf('selectable') > -1) {
            // No sections in the template
            const currentLenght = selectedSections.length;
            setSelectedSections((prev) => [...prev, DEFAULT_TEMPLATE_SECTIONS[sectionReference]]);
            insertSectionAtPosition(currentLenght, DEFAULT_TEMPLATE_SECTIONS[sectionReference]);
            return;
        }
    };

    const handleSave = async (id, selectedSectionsClone) => {
        const sections = selectedSectionsClone ? selectedSectionsClone : selectedSections;
        const data = {
            name: name || 'Nueva plantilla',
            sections: sections.map((s, i) => {
                return {
                    position: i,
                    type: s.type,
                    sectionTitle: s.sectionTitle,
                    subject: s.subject,
                    sectionId: s.sectionId
                };
            })
        };

        const res = id
            ? await updateReportTemplateSections({ ...data, reportTemplateId: id })
            : await createReportTemplate(data);
        const updatedTemplate = await getReportTemplateById({ reportTemplateId: id ? id : res.id });

        if (!id) navigate(`/templates/edit/${res.id}`);
        return updatedTemplate;
    };

    const handleChangeName = async (newName) => {
        // TODO: fix when just enter the template, click in the name, not introducing content and clicking outside: the name changes to Nueva Plantilla

        const data = {
            name: newName ? newName : name || 'Nueva plantilla',
            // updating without sections we get an error
            sections: selectedSections.map((s, i) => {
                return {
                    position: i,
                    type: s.type,
                    sectionTitle: s.sectionTitle,
                    subject: s.subject
                };
            })
        };
        setName(data.name);

        const res = templateId
            ? await updateReportTemplateName({ name: data.name, reportTemplateId: templateId })
            : await createReportTemplate(data);

        if (!templateId) navigate(`/templates/edit/${res.id}`);
    };

    const handleSetActive = async (reportTemplateId) => {
        let res;

        if (!reportTemplateId) {
            const data = {
                name: name || 'Nueva plantilla',
                sections: selectedSections.map((s, i) => {
                    return {
                        position: i,
                        type: s.type,
                        sectionTitle: s.sectionTitle,
                        subject: s.subject
                    };
                })
            };

            res = await createReportTemplate(data);
            navigate(`/templates/edit/${res.id}`);
        }

        await setActiveReportTemplate({ reportTemplateId: reportTemplateId || res.id });
        setIsActive(true);
    };

    const handleShare = async (reportTemplateId) => {
        let res;

        if (!reportTemplateId) {
            const data = {
                name: name || 'Nueva plantilla',
                sections: selectedSections.map((s, i) => {
                    return {
                        position: i,
                        type: s.type,
                        sectionTitle: s.sectionTitle,
                        subject: s.subject
                    };
                })
            };

            res = await createReportTemplate(data);
            navigate(`/templates/edit/${res.id}`);
        }

        await shareTemplate({ templateId: reportTemplateId || res.id });
        setIsShared(!isShared);
    };

    const handleChangeReportLanguage = async (languageCode) => {
        await updateReportTemplateLanguage({
            language: languageCode,
            reportTemplateId: templateId
        });
        setReportLanguage(languageCode);
    };

    const handleAddNewSection = (newSection) => {
        setUserSections([newSection, ...(userSections || [])]);
    };

    const handleRemoveSection = (deletedSection) => {
        setUserSections(userSections.filter((section) => section.id !== deletedSection.id));
    };

    const findAndUpdateSection = ({ id, name, goal, guidelines, formatType }) => {
        return new Promise((solve) => {
            const index = userSections.findIndex((section) => {
                return section.id === id;
            });

            let updatedUserSections = [...userSections];
            if (name) {
                updatedUserSections[index] = {
                    ...updatedUserSections[index],
                    name,
                    props: {
                        ...updatedUserSections[index].props,
                        title: name
                    }
                };
            }
            if (goal) {
                updatedUserSections[index] = {
                    ...updatedUserSections[index],
                    goal,
                    description: goal
                };
            }
            if (guidelines) {
                updatedUserSections[index] = { ...updatedUserSections[index], guidelines };
            }
            if (formatType) {
                updatedUserSections[index] = {
                    ...updatedUserSections[index],
                    formatType,
                    props: {
                        ...updatedUserSections[index].props,
                        icon: getIconFromFormatType(formatType)
                    }
                };
            }
            setUserSections(updatedUserSections);
            solve(updatedUserSections[index]);
        });
    };

    const handleUpdateSection = async ({ id, name, goal, guidelines, formatType }) => {
        try {
            const status = await updateSection({
                sectionId: id,
                name,
                goal,
                guidelines,
                formatType
            });
            if (status === 200) {
                findAndUpdateSection({ id, name, goal, guidelines, formatType });
                return 200;
            }
            notification('update-section-failure');
        } catch (error) {
            notification('update-section-failure');
        }
    };

    return (
        <>
            <Page title={name || 'Nueva plantilla'}>
                <Box sx={{ display: 'flex', minHeight: '100vh', paddingTop: 8 }}>
                    <Box
                        component='main'
                        sx={{
                            backgroundColor: 'white',
                            flexGrow: 1,
                            height: 'auto',
                            overflow: 'auto'
                        }}
                    >
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <HideableLayout
                                sticky={true}
                                breadcrumbProps={{
                                    previousPage: previousPage,
                                    currentPageTitle: name || 'Nueva plantilla'
                                }}
                                handleChangeTitle={handleChangeName}
                                content={
                                    <>
                                        {getPlan().name !== subscriptionPlans.unlimited.name && (
                                            <Box mb={2}>
                                                <InformationAlert
                                                    message='Actualiza al <b>plan Unlimited</b> para usar tus plantillas personalizadas.'
                                                    onClick={() => setOpenPremiumDialog(true)}
                                                    buttonText='Mejorar plan'
                                                />
                                            </Box>
                                        )}
                                        <TemplateDragDropSections
                                            onDelete={(index) => handleDeleteSection(index)}
                                            onChange={() => setNewChange(true)}
                                            sections={selectedSections}
                                            templateId={Number(templateId)}
                                            setSectionIdFromTemplate={setSectionIdFromTemplate}
                                        />
                                    </>
                                }
                                hideableContent={
                                    <TemplateSideMenu
                                        isActive={isActive}
                                        handleSetActive={() => handleSetActive(templateId)}
                                        isShared={isShared}
                                        handleShare={() => handleShare(templateId)}
                                        personalizedSections={{ userSections, workspaceSections }}
                                        reportLanguage={reportLanguage}
                                        handleChangeReportLanguage={handleChangeReportLanguage}
                                        templateId={templateId}
                                        handleAddNewSection={handleAddNewSection}
                                        handleRemoveSection={handleRemoveSection}
                                        handleUpdateSection={handleUpdateSection}
                                        sectionIdFromTemplate={sectionIdFromTemplate}
                                        setSectionIdFromTemplate={setSectionIdFromTemplate}
                                    />
                                }
                            />
                        </DragDropContext>
                    </Box>
                </Box>
            </Page>
            <PricingDialog
                openDialog={openPremiumDialog}
                setOpenDialog={setOpenPremiumDialog}
                title='Actualiza a Unlimited para desbloquear tus plantillas'
                defaultPlanId={1}
            />
        </>
    );
}
