import React, { useEffect, useState } from 'react';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import { PropTypes } from 'prop-types';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import { getColorFromLetter } from '@common/utils/colors';
import { getLanguageFlag } from '@common/utils/languages';
import { getAllReportTemplates } from '@setup/api/reportTemplates/reportTemplates';
import IconButtonWithTooltip from './buttons/IconButtonWithTooltip';
import Tag from './tagsComponents/Tag';

TemplateSelector.propTypes = {
    setTemplate: PropTypes.func,
    template: PropTypes.any
};

export default function TemplateSelector({ setTemplate, template }) {
    const { getPlan } = useUser();

    const defaultTemplate = {
        author: 'Voicit',
        id: null,
        isActive: true,
        name: 'Resumen por defecto de Voicit',
        updatedAt: '2024-03-05T07:33:18.272Z',
        language: null
    };

    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [anchorElChip, setAnchorElChip] = useState(null);
    const [templates, setTemplates] = useState();
    const [currentTemplate, setCurrentTemplate] = useState();

    useEffect(() => {
        (async () => {
            const { data } = await getAllReportTemplates();
            const availableTemplates = data.userTemplates;
            setTemplates(availableTemplates);
            if (template) {
                setCurrentTemplate(template);
            } else {
                setCurrentTemplate(
                    getPlan().name !== subscriptionPlans.unlimited.name
                        ? defaultTemplate
                        : availableTemplates.find((t) => t.isActive === true)
                );
            }
        })();
    }, []);

    useEffect(() => {
        setTemplate(currentTemplate);
    }, [currentTemplate]);

    const handleOnTemplateChange = (selectedTemplate) => {
        if (getPlan().name !== subscriptionPlans.unlimited.name) {
            setOpenPremiumDialog(true);
            handleCloseList();
        } else {
            setCurrentTemplate(selectedTemplate);
            handleCloseList();
        }
    };

    const handleOpenList = (event) => {
        setAnchorElChip(event.currentTarget);
    };

    const handleCloseList = () => {
        setAnchorElChip(null);
    };

    const filterTemplatesList = (currentTagId) => {
        templates(setTemplates.filter((tagData) => tagData.id !== currentTagId));
    };

    return (
        <Grid
            container
            sx={{ direction: 'row', alignItems: 'center', flexDirection: 'row', flexGrow: 1 }}
        >
            {currentTemplate ? (
                <Grid item>
                    <Tag
                        tag={{
                            ...currentTemplate,
                            color: getColorFromLetter(currentTemplate.name).chip
                        }}
                        src={getLanguageFlag(currentTemplate.language)}
                    />
                </Grid>
            ) : null}
            <Grid item sx={{ ml: 2 }}>
                {templates && templates.length > 0 ? (
                    <>
                        <IconButtonWithTooltip
                            icon={<ExpandMoreOutlinedIcon />}
                            tooltipText={'Cambiar plantilla'}
                            onClick={handleOpenList}
                        />
                        <Popover
                            sx={{ mt: '45px', maxHeight: 450, display: 'flex' }}
                            id='menu-filters'
                            anchorEl={anchorElChip}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                            open={Boolean(anchorElChip)}
                            onClose={handleCloseList}
                        >
                            <Grid
                                container
                                spacing={0}
                                direction={'column'}
                                sx={{ alignItems: 'left', m: 0, mb: 1 }}
                            >
                                <Grid item>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction={'column'}
                                        sx={{ alignItems: 'left' }}
                                    >
                                        {templates.map((template) => (
                                            <Grid
                                                item
                                                key={template.id}
                                                sx={{ mt: 1, ml: 1, mr: 1 }}
                                            >
                                                <Stack
                                                    direction='row'
                                                    spacing={1}
                                                    alignItems='center'
                                                    display='flex'
                                                >
                                                    <Tag
                                                        onTagSelection={handleOnTemplateChange}
                                                        tag={{
                                                            ...template,
                                                            color: getColorFromLetter(template.name)
                                                                .chip
                                                        }}
                                                        filterMeetingList={filterTemplatesList}
                                                        disableFullWidth={true}
                                                        src={getLanguageFlag(template.language)}
                                                    />
                                                </Stack>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Popover>
                    </>
                ) : null}
            </Grid>
            <PricingDialog
                openDialog={openPremiumDialog}
                setOpenDialog={setOpenPremiumDialog}
                title='Actualiza a Unlimited para usar tus plantillas'
                defaultPlanId={1}
            />
        </Grid>
    );
}
