import { useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ToggleButton from '@common/components/dialogs/plansDialog/ToggleButton';
import TemplateMenuCategories from './tabs/categories/TemplateMenuCategories';
import TemplateMenuConfig from './tabs/config/TemplateMenuConfig';

TemplateSideMenu.propTypes = {
    isActive: PropTypes.bool,
    handleSetActive: PropTypes.func.isRequired,
    isShared: PropTypes.bool,
    handleShare: PropTypes.func.isRequired,
    personalizedSections: PropTypes.any.isRequired,
    reportLanguage: PropTypes.string.isRequired,
    handleChangeReportLanguage: PropTypes.func.isRequired,
    handleAddNewSection: PropTypes.func.isRequired,
    handleRemoveSection: PropTypes.func.isRequired,
    handleUpdateSection: PropTypes.func.isRequired,
    sectionIdFromTemplate: PropTypes.number,
    setSectionIdFromTemplate: PropTypes.func.isRequired
};

const toggleOptions = [
    { key: 'sections', label: 'Secciones' },
    { key: 'options', label: 'Opciones' }
];

export default function TemplateSideMenu({
    isActive,
    handleSetActive,
    isShared,
    handleShare,
    personalizedSections,
    reportLanguage,
    handleChangeReportLanguage,
    handleAddNewSection,
    handleRemoveSection,
    handleUpdateSection,
    sectionIdFromTemplate,
    setSectionIdFromTemplate
}) {
    const [currentTab, setCurrentTab] = useState(toggleOptions[0].key);

    const handleChangeTab = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    return (
        <Grid container direction='column' sx={{ ml: 2 }}>
            <Grid item sx={{ mb: 2 }}>
                <ToggleButton
                    options={toggleOptions}
                    defaultOptionId={
                        currentTab
                            ? toggleOptions.findIndex((option) => option.key === currentTab)
                            : 0
                    }
                    fullWidth={true}
                    handleChange={(tabValue) => {
                        handleChangeTab(toggleOptions[tabValue].key);
                    }}
                />
            </Grid>
            {currentTab === toggleOptions[0].key && (
                <Grid item>
                    <TemplateMenuCategories
                        personalizedSections={personalizedSections}
                        handleAddNewSection={handleAddNewSection}
                        handleRemoveSection={handleRemoveSection}
                        handleUpdateSection={handleUpdateSection}
                        sectionIdFromTemplate={sectionIdFromTemplate}
                        setSectionIdFromTemplate={setSectionIdFromTemplate}
                    />
                </Grid>
            )}
            {currentTab === toggleOptions[1].key && (
                <Grid item>
                    <TemplateMenuConfig
                        isActive={isActive}
                        handleSetActive={handleSetActive}
                        isShared={isShared}
                        handleShare={handleShare}
                        reportLanguage={reportLanguage ? reportLanguage : null}
                        handleChangeReportLanguage={handleChangeReportLanguage}
                    />
                </Grid>
            )}
        </Grid>
    );
}
