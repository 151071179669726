import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { PropTypes } from 'prop-types';

TooltipIcon.propTypes = {
    icon: PropTypes.node,
    tooltipText: PropTypes.string,
    sx: PropTypes.object
};

export default function TooltipIcon({ sx, tooltipText, icon }) {
    return (
        <Tooltip sx={sx} title={tooltipText}>
            {icon}
        </Tooltip>
    );
}
