import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createWorkspace, getWorkspace, getWorkspaceMembers } from '@setup/api/workspace';
import useAuthToken from '@setup/hooks/useAuthToken';
import { setAuthToken } from '@setup/redux/reducers/authSlice';

export default function useWorkspace() {
    const authToken = useAuthToken();
    const dispatch = useDispatch();
    const userEmail = useSelector((state) => state.user.userEmail);
    const [workspace, setWorkspace] = useState({});
    const [workspaceExists, setWorkspaceExists] = useState(false);
    const [newWorkspaceName, setNewWorkspaceName] = useState();
    const [workspaceMembers, setWorkspaceMembers] = useState([]);
    const [isWorkspaceAdmin, setIsWorkspaceAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                await handleGetUserWorkspace();
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
            }
        })();
    }, [authToken]);

    useEffect(() => {
        (async () => {
            if (Object.keys(workspace).length > 0) {
                setIsLoading(true);
                await handleGetWorkspaceMembers();
                setWorkspaceExists(true);
                setIsLoading(false);
            }
        })();
    }, [workspace]);

    const handleWorkspaceCreation = async () => {
        // TODO: When creating a workspace the user has to relogin
        const newAuthToken = await createWorkspace(newWorkspaceName);
        dispatch(setAuthToken(newAuthToken));
    };

    const handleGetUserWorkspace = async () => {
        const data = await getWorkspace();
        if (data) {
            setWorkspace(data);
        }
    };

    const handleGetWorkspaceMembers = async () => {
        const currentWorkspaceMembers = await getWorkspaceMembers(newWorkspaceName);
        setWorkspaceMembers(currentWorkspaceMembers);
        setIsWorkspaceAdmin(
            Boolean(
                currentWorkspaceMembers.find((m) => m.email === userEmail).workspaceRole === 'ADMIN'
            )
        );
    };

    return {
        getWorkspaceMembers,
        handleWorkspaceCreation,
        workspaceMembers,
        setNewWorkspaceName,
        workspace,
        setWorkspace,
        isWorkspaceAdmin,
        isLoading,
        handleGetWorkspaceMembers,
        workspaceExists
    };
}
