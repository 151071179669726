import React, { useState } from 'react';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Button, Chip, Grid, IconButton, InputBase, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import VoicitAvatar from '@common/components/avatars/VoicitAvatar';
import PricingDialog from '@common/components/dialogs/PricingDialog';
import { urls } from '@common/helpers/urls';
import { subscriptionPlans } from '@common/helpers/user';
import useUser from '@common/hooks/useUser';
import palette from '@common/theme/palette/palette';
import { chat, completion } from '@setup/api/nlp';
import { copyReportBlocksContent } from '@setup/api/reportBlocks/reportBlocks';
import ChatMessage from './ChatMessage';

export default function Chat({ meetingId, messages, setMessages }) {
    const { getPlan } = useUser();
    const userUseCase = useSelector((state) => state.user.userUseCase);
    const userGivenName = useSelector((state) => state.user.userGivenName);

    const [inputValue, setInputValue] = useState('');
    const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
    const [quickReplying, setQuickReplying] = useState('');

    const handleSubmitMessage = async (userMessage) => {
        if (getPlan().name !== subscriptionPlans.unlimited.name) {
            setOpenPremiumDialog(true);
            return;
        }

        if (inputValue.trim()) {
            setMessages([
                ...messages,
                { role: 'user', content: inputValue },
                {
                    role: 'assistant',
                    content: '',
                    isLoading: true
                }
            ]);
            setInputValue('');
            if (quickReplying) {
                await handleNewCompletionMessage(userMessage);
            } else {
                await handleNewChatMessage(userMessage);
            }
        }
    };

    const handleKeyDown = async (event) => {
        const userMessage = inputValue;
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            await handleSubmitMessage(userMessage);
        }
    };

    const handleNewChatMessage = async (userMessage) => {
        const chatResponse = await chat({
            newMessage: userMessage,
            thread: messages,
            meetingId
        });
        setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1), // Remove the last message
            { role: 'assistant', content: chatResponse } // Add the assistant's response
        ]);
    };

    const handleNewCompletionMessage = async (userMessage) => {
        const reportContent = await copyReportBlocksContent(meetingId);
        const completionResponse = await completion({
            prompt:
                `Aquí tienes el informe de Toma de Requerimientos generado en la reunión con el cliente para definir una oferta de empleo: ${reportContent}.` +
                (quickReplying === 'jobDescription'
                    ? 'Utiliza el informe para crear una descripción de para la oferta de empleo.'
                    : 'Utiliza el informe para crear un post para LinkedIn para publicar el lanzamiento de una nueva oferta de empleo a tu red de contactos.') +
                'Adapta el contenido según las siguientes indicaciones: ' +
                userMessage +
                'No uses markdown.',
            system: 'Eres un consultor de selección.',
            config: {
                model: 'gpt-4o',
                temperature: 0.7
            },
            user: userGivenName
        });

        setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1), // Remove the last message
            { role: 'assistant', content: completionResponse.content } // Add the assistant's response
        ]);
        setQuickReplying('');
    };

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleQuickReply = async (type) => {
        if (getPlan().name !== subscriptionPlans.unlimited.name) {
            setOpenPremiumDialog(true);
            return;
        }
        setQuickReplying(type);
        setMessages([
            ...messages,
            {
                // Just to show next message
                role: 'system',
                content: '',
                hide: true
            },
            {
                role: 'assistant',
                content:
                    'Dame un ejemplo o varios para adaptarme. Usaré la información del informe de Voicit, indícame cualquier detalle adicional que quieras que tenga en cuenta.'
            }
        ]);
        setInputValue('');
    };

    return (
        <>
            <Grid
                container
                direction='column'
                sx={{
                    height: '100vh',
                    position: 'relative',
                    maxWidth: '100%'
                }}
            >
                <Grid
                    item
                    sx={{
                        flexGrow: messages.length > 1 ? 1 : 0,
                        overflowY: 'auto',
                        padding: 2,
                        mb: 5,
                        '&::-webkit-scrollbar': {
                            width: '10px'
                        },
                        '&::-webkit-scrollbar-track': {
                            background: palette.grey[300],
                            borderRadius: '10px'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: palette.grey[600],
                            borderRadius: '10px',
                            border: `2px solid ${palette.grey[100]}`
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: palette.grey[700]
                        }
                    }}
                >
                    {messages.length > 1 ? (
                        <>
                            {messages.map((message, index) => (
                                <div key={index}>
                                    {message.hide ? null : (
                                        <ChatMessage
                                            role={message.role}
                                            content={message.content}
                                            isLoading={
                                                message.isLoading ? message.isLoading : false
                                            }
                                            position={index}
                                            messagesCount={messages.length}
                                        />
                                    )}
                                </div>
                            ))}
                            {messages.length > 3 &&
                                messages[messages.length - 1].role === 'assistant' &&
                                messages[messages.length - 1].content && (
                                    <Grid
                                        item
                                        xs={12}
                                        display={'flex'}
                                        justifyContent='flex-start'
                                        alignItems='center'
                                    >
                                        <Button
                                            variant='text'
                                            startIcon={<CampaignOutlinedIcon fontSize='small' />}
                                            onClick={() => window.open(urls.forms.chatbotFeedback)}
                                        >
                                            ¿Te está resultando útil?
                                        </Button>
                                    </Grid>
                                )}
                        </>
                    ) : (
                        <Grid
                            container
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                            spacing={2}
                        >
                            <Grid item>
                                <VoicitAvatar
                                    size={42}
                                    sx={{
                                        animationName: 'gradient',
                                        animationDuration: '2s',
                                        animationIterationCount: 'infinite',
                                        background: `linear-gradient(45deg, ${palette.secondary[300]}, ${palette.secondary[700]})`,
                                        backgroundSize: '200% 200%',
                                        transition: `background 0.5s ease-in-out`,
                                        border: `1px solid ${palette.secondary[600]}`
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <Typography component={'span'} variant='h4'>
                                    Report Booster
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2' textAlign='center'>
                                    Al salir de la reunión perderás el historial de este chat.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    container
                    direction='column'
                    sx={{
                        position: messages.length > 1 ? 'fixed' : 'unset',
                        mt: 2,
                        p: '10%',
                        pt: 3,
                        pb: 1,
                        bottom: 0,
                        width: '100%',
                        right: 0,
                        backgroundColor: 'rgba(255, 255, 255, 0.4)', // Slightly transparent background
                        backdropFilter: 'blur(10px)', // Blur effect
                        borderTop:
                            messages.length > 1 ? `1px solid ${palette.primary[100]}` : 'none', // Optional: Add a top border to separate it visually
                        zIndex: messages.length > 1 ? 9999 : 0
                    }}
                >
                    <Grid item>
                        <Stack
                            direction='row'
                            spacing={1}
                            alignItems='center'
                            sx={{
                                backgroundColor: palette.primary[50],
                                padding: 1,
                                borderRadius: 2,
                                boxSizing: 'border-box',
                                border: `1px solid ${palette.primary[200]}`
                            }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder={
                                    quickReplying
                                        ? 'Añade un ejemplo y los detalles que necesites'
                                        : 'Pregunta sobre tu conversación'
                                }
                                inputProps={{ 'aria-label': 'search google maps' }}
                                onKeyDown={handleKeyDown}
                                onChange={handleChange}
                                value={inputValue}
                                autoFocus={true}
                                fullWidth={true}
                                multiline={true}
                            />
                            <IconButton
                                type='button'
                                sx={{ p: 1, borderRadius: 2 }}
                                aria-label='search'
                                onClick={() => handleSubmitMessage(inputValue)}
                            >
                                <ArrowUpwardRoundedIcon />
                            </IconButton>
                        </Stack>
                    </Grid>

                    {userUseCase === 'hiring' && (
                        <Grid item display='flex' justifyContent='center' mt={1}>
                            <Stack direction='row' spacing={1}>
                                <Chip
                                    variant='outlined'
                                    icon={<DescriptionOutlinedIcon fontSize='small' />}
                                    label='Crear descripción de trabajo'
                                    onClick={() => handleQuickReply('jobDescription')}
                                    size='medium'
                                />
                                <Chip
                                    variant='outlined'
                                    icon={<ArticleOutlinedIcon fontSize='small' />}
                                    label='Crear post LinkedIn'
                                    onClick={() => handleQuickReply('linkedinPost')}
                                    size='medium'
                                />
                            </Stack>
                        </Grid>
                    )}

                    <Grid item display='flex' justifyContent='center' mt={1}>
                        <Typography
                            variant='caption'
                            textAlign='center'
                            color={palette.primary[300]}
                        >
                            Pueden existir errores. Utiliza las referencias de tiempo y el audio
                            para verificar.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <PricingDialog
                openDialog={openPremiumDialog}
                setOpenDialog={setOpenPremiumDialog}
                defaultPlanId={1}
                title='Actualiza a Unlimited para usar Report Booster'
            />
        </>
    );
}

Chat.propTypes = {
    meetingId: PropTypes.number.isRequired,
    meetingDuration: PropTypes.number.isRequired,
    messages: PropTypes.arrayOf(PropTypes.any).isRequired,
    setMessages: PropTypes.func.isRequired,
    meetingTitle: PropTypes.string.isRequired
};
