import AddIcon from '@mui/icons-material/Add';
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import Avatar from '@common/components/avatars/Avatar';
import TooltipIcon from '@common/components/icons/TooltipIcon';
import MenuButton from '@common/components/MenuButton';
import palette from '@common/theme/palette/palette';
import { getColorFromLetter } from '@common/utils/colors';
import { getLanguageFlag } from '@common/utils/languages';
import { duplicateTemplate } from '@setup/api/reportTemplates/reportTemplates';
import TemplateSkeleton from './TemplateCardSkeleton';

TemplateItem.propTypes = {
    isAddHandler: PropTypes.bool,
    id: PropTypes.number,
    isActive: PropTypes.bool,
    isShared: PropTypes.bool,
    name: PropTypes.string,
    tag: PropTypes.any,
    onSetActive: PropTypes.func,
    onDelete: PropTypes.func,
    onNameChange: PropTypes.func,
    onHandlerClick: PropTypes.func,
    editionDate: PropTypes.string,
    language: PropTypes.string
};

export default function TemplateItem({
    isAddHandler,
    onHandlerClick,
    name,
    isActive,
    isShared,
    id,
    onDelete,
    onSetActive,
    onNameChange,
    editionDate,
    language
}) {
    const navigate = useNavigate();

    const handleSetActive = async () => {
        onSetActive(id);
    };
    const handleDuplicate = async () => {
        const duplicatedTemplate = await duplicateTemplate({ templateId: id });
        navigate(`/templates/edit/${duplicatedTemplate.id}`);
    };

    return (
        <Box id='editTemplate'>
            {isAddHandler && (
                <TemplateSkeleton
                    borderType='dashed'
                    borderWidth={2}
                    shadow='none'
                    borderColor={palette.primary[100]}
                >
                    <Stack
                        width='100%'
                        direction='column'
                        display='flex'
                        onClick={onHandlerClick}
                        justifyContent='center'
                        alignItems='center'
                        spacing={2}
                    >
                        <Box
                            sx={{ borderRadius: '100%' }}
                            width='50px'
                            height='50px'
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            bgcolor={palette.primary[50]}
                        >
                            <AddIcon fontSize='small' />
                        </Box>
                        <Typography>Nueva plantilla</Typography>
                    </Stack>
                </TemplateSkeleton>
            )}
            {!isAddHandler && (
                <TemplateSkeleton
                    borderType='solid'
                    borderWidth={1}
                    shadow={0}
                    borderColor={palette.primary[100]}
                >
                    <Grid xs={12} container>
                        <Grid
                            id='editTemplate'
                            item
                            height='120px'
                            onClick={() => {
                                navigate(`/templates/edit/${id}`);
                            }}
                        >
                            <Stack p={2} spacing={1}>
                                <Typography
                                    id='editTemplate'
                                    variant='h5'
                                    fontWeight='regular'
                                    color={palette.primary[800]}
                                >
                                    {name}
                                </Typography>
                                <Stack direction='row' alignItems='center' spacing={1}>
                                    {editionDate && (
                                        <Typography
                                            id='editTemplate'
                                            variant='body2'
                                            fontWeight='regular'
                                            color={palette.primary[200]}
                                        >
                                            Editado hace {editionDate}
                                        </Typography>
                                    )}
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid
                            width='100%'
                            height='49px'
                            display='flex'
                            alignItems='center'
                            p={2}
                            sx={{
                                borderBottomLeftRadius: '8.5px',
                                borderBottomRightRadius: '8.5px'
                            }}
                            bgcolor={getColorFromLetter(name).variant}
                            item
                        >
                            <Grid
                                item
                                xs={12}
                                display='flex'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <Stack direction='row' spacing={1}>
                                    {isActive && (
                                        <Chip
                                            label={'Activa'}
                                            size='small'
                                            color='chip_primary_dark'
                                        />
                                    )}
                                    {isShared && (
                                        <TooltipIcon
                                            tooltipText='Compartida con equipo'
                                            icon={
                                                <ShareOutlinedIcon
                                                    fontSize='small'
                                                    color='primary'
                                                />
                                            }
                                        />
                                    )}
                                    {language && (
                                        <Avatar
                                            size='small'
                                            avatarImage={getLanguageFlag(language)}
                                            tooltip={true}
                                            customTooltipText={'Idioma del informe'}
                                            sx={{ borderRadius: '100%' }}
                                        />
                                    )}
                                </Stack>
                                <Grid item>
                                    <MenuButton
                                        iconButton={
                                            <MoreVertIcon fontSize='small' color='primary' />
                                        }
                                        tooltipText='Opciones'
                                        menuList={[
                                            {
                                                itemFunction: () => handleSetActive(),
                                                itemIcon: (
                                                    <ToggleOffOutlinedIcon fontSize='small' />
                                                ),
                                                itemText: 'Activar',
                                                disabled: isActive
                                            },
                                            {
                                                itemFunction: () =>
                                                    navigate(`/templates/edit/${id}`),
                                                itemIcon: <CallMadeRoundedIcon fontSize='small' />,
                                                itemText: 'Editar'
                                            },
                                            {
                                                itemFunction: () => handleDuplicate(),
                                                itemIcon: (
                                                    <ContentCopyOutlinedIcon fontSize='small' />
                                                ),
                                                itemText: 'Duplicar'
                                            },
                                            {
                                                itemFunction: () => onNameChange(id),
                                                itemIcon: <EditIcon fontSize='small' />,
                                                itemText: 'Cambiar nombre'
                                            },
                                            {
                                                itemFunction: () => onDelete(id),
                                                itemIcon: <DeleteIcon fontSize='small' />,
                                                itemText: 'Eliminar',
                                                disabled: isActive
                                            }
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </TemplateSkeleton>
            )}
        </Box>
    );
}
